import actions from "@iso/redux/apiRoom/actions";

const initialState = {
  apiRoomOtas: [],
  room: {},
  total: 0,
  loading: true,
  openSaleLoading: false,
  openStopSaleLoading: false,
};

export default function getRooms(state = initialState, action) {
  switch (action.type) {
    case actions.GET_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ROOM_SUCCESS:
      return {
        ...state,
        apiRoomOtas: action.apiRoomOtas,
        total: action.total,
        loading: false,
      };
    case actions.GET_ROOM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.GET_ROOM_DETAIL_REQUEST:
      return {
        ...state,
      };
    case actions.GET_ROOM_DETAIL_SUCCESS:
      return {
        ...state,
        loading: true,
        room: action.room
      };
    case actions.GET_ROOM_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.SYNC_DATA_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.SYNC_DATA_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.SYNC_DATA_ROOM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.RELOAD_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.RELOAD_ROOM_SUCCESS:
      return {
        ...state,
        room: action.room,
        loading: false,
      };
    case actions.RELOAD_ROOM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.UPDATE_DATA_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_DATA_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_DATA_ROOM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.OPEN_SALE_SINGLE_ROOM_REQUEST:
      return {
        ...state,
        openSaleLoading: true,
      };
    case actions.OPEN_SALE_SINGLE_ROOM_SUCCESS:
      return {
        ...state,
        openSaleLoading: false,
      };
    case actions.OPEN_SALE_SINGLE_ROOM_ERROR:
      return {
        ...state,
        openSaleLoading: false,
        error: action.error,
      };
    case actions.STOP_SELLING_ROOM_REQUEST:
      return {
        ...state,
        openStopSaleLoading: true,
      };
    case actions.STOP_SELLING_ROOM_SUCCESS:
      return {
        ...state,
        openStopSaleLoading: false,
      };
    case actions.STOP_SELLING_ROOM_ERROR:
      return {
        ...state,
        openStopSaleLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
